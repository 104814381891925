header{
    height: 100vh;
    padding-top: 7rem;
    overflow: hidden;
}
.header__container{
    text-align: center;
    height: 100vh;
    position: relative;
}

/* ========== CTA ======================= */
.cta{
    margin-top: 2.5rem;
    display: flex;
    gap: 1.2rem;justify-content: center;
    margin-bottom: 2rem;
}

/* ============== HEADER SOCIALS ================= */
.header__socials{
    display: flex;
    flex-direction: column;
    align-items: center;
    position: absolute;
    left: 0;
    bottom: 3rem;
    gap: 2rem;
    font-size: 2rem;
}
.header__socials::after{
    content: "";
    width: 1px;
    height: 2rem;
    background: var(--color-primary);
}

/* ================ ME ================= */
.me{
    /* background: linear-gradient(var(--color-primary)),transparent;
    width: 20rem;
    height: 50rem;
    position: absolute;
    left: calc(50% - 11rem);
    border-radius: 12rem 12rem 0 0;
    overflow: hidden;
    padding: 5rem 1.5rem 1.5rem 1.5rem; */

    height: 20rem;
	width: 20rem;
	border-radius: 50%;
	background-color: var(--color-primary);
    border: 3px dotted rgb(195, 14, 14);
    position: absolute;
    left: calc(50% - 11rem);
    padding: 0.5rem 0 0 0;
    animation: blink 1s;
    animation-iteration-count: infinite;
}
@keyframes blink { 50% { border-color:#fff ; }  }

/* ==================== SCROLL DOWN ========================== */
.scroll__down{
    position: absolute;
    right: -2.3rem;
    transform: rotate(90deg);
    font-weight: 300;
    font-size: 0.9rem;
    display: inline-flex;
    
}
/*  =============== MEDIA QUERIES (MEDIUM DEVICES) ======================== */
@media screen and (max-width: 1024px) {
    header{
        height: 68vh;
    }
}

/*  =============== MEDIA QUERIES (SMALL DEVICES) ======================== */
@media screen and (max-width: 600px) {
    header{
        height: 100vh;
    }
    .header__socials ,.scroll__down{
        display: none;
    }
}